<template>
    <div>
        <b-row class="sticky-top">
            <b-col sm="12" md="6" lg="6" class="form-group bg-white py-2">
                <h4 class="p-0 mt-1 text-mary-help-purple">Novo Treinamentos</h4>
            </b-col>
            <b-col sm="12" md="3" lg="3" class="form-group bg-white py-2">
                <router-link class="btn btn-outline-mary-help btn-block" to="/admin/treinamentos"><b-icon icon="reply" flip-h class="mr-2" />Voltar</router-link>
            </b-col>
            <b-col sm="12" md="3" lg="3" class="form-group bg-white py-2">
                <b-button v-on:click="verifyForm" variant="mary-help" block><b-icon icon="cloud-arrow-up" class="mr-2" />Salvar</b-button>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-form-group id="title" label-for="title">
                    <label class="required">Título</label>
                    <b-form-input v-model="title" id="title" type="text" required placeholder="Informe um Título"></b-form-input>
                    <b-form-invalid-feedback :state="!errors.title">
                        <div v-for="error in errors.title" :key="error">
                            {{ error }}
                        </div>
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-form-group id="description" label="Descrição" label-for="description" description="Fale, resumidamente, o objetivo ou qual o conteúdo do curso.">
                    <b-form-input v-model="description" id="description" type="text" required placeholder="Informe uma Descrição"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            if (this.$route.params.id) {
                this.$store.dispatch("training/loadEditForm", this.$route.params.id);
            } else {
                this.$store.dispatch("training/loadInsertForm");
            }
        },

        verifyForm(){
            var training = this.$store.state.training.training;
            var error = {};
            training.title === '' ? error = {title: ['Campo Título é Obrigatório']} : null
  
            if(Object.keys(error).length === 0){
                this.addError({});
                this.Save()
            }else{
                this.addError(error);
            }
        },

        Save() {

            if(!this.$route.params.id) {
                this.$store.dispatch('training/createTraining').then(response => {
                    this.sweealert2('success', response.success)
                    this.$router.push('/admin/treinamentos')
                }).catch(error => {
                    this.addError(error.response)
                    this.sweealert2('error', error.response.data.error)
                });
            } else {
                this.$store.dispatch('training/updateTraining').then(response => {
                    this.sweealert2('success', response.success)
                    this.$router.push('/admin/treinamentos')
                }).catch(error => {
                    this.addError(error.response)
                    this.sweealert2('error', error.response.data.error)
                });
            }
        },

        addError(error){
            this.$store.commit('training/SET_TRAINING_ERROR', error)
        },

        sweealert2(icon, text) {
            this.$swal({
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                text: text,
                icon: icon,
                padding: 20,
            })
        }
    },
    computed: {
        ...mapGetters('training', [
            'IsUpdate'
        ]),

        title: {
            get() {
                return this.$store.state.training.training.title;
            },
            set(value) {
                this.$store.commit('training/SET_TITLE', value)
            }
        },

        description: {
            get() {
                return this.$store.state.training.training.description;
            },
            set(value) {
                this.$store.commit('training/SET_DESCRIPTION', value)
            }
        },

        errors: {
            get() {
                return this.$store.state.training.errors;
            },
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.title {
    border-bottom: solid 1px $color-grey-700;

    h5 {
        color: $color-grey-700;
    }
}
</style>