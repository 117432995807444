<template>
    <div id="dashboard" class="container py-5">
        <b-row>
            <b-col sm="12" lg="4" md="4" class="my-3" v-for="(item, index) in items" :key="index">
                <div class="training">
                    <div class="header text-center py-3">
                        <img src="@/assets/img/education.png" />
                    </div>

                    <div class="description text-center py-3 px-4 text-mary-help-purple">
                        <h4>{{ item.title }}</h4>
                    </div>

                    <div class="px-2 pb-3">
                        <router-link :to="`/manual/${item.id}`" class="btn btn-mary-help btn-block button-exit">
                            <b-icon icon="arrow-up-right-circle" class="mr-2" />Acessar Treinamentos
                        </router-link>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    components: {
    },    
    data() {
        return {
            loadingData: false,
            table_numbers: {
                size: 1,
                total: 3,
                found: 3,
                footer: null
            },
            filters: {
                show: 10,
                title: '',
                period: null
            },
            items: [],
        }
    },
    created() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.loadingData = true;

            this.$store.dispatch('training/getTrainings', null).then(data => {
                this.loadingData = false;
                this.items = data ? data : [];
            });
        },
    }
}
</script>

<style lang="scss">
.training {
    width: 100%;
    border-radius: 5px;
    -webkit-box-shadow: 3px 3px 12px 0px rgba(230,230,230,1);
    -moz-box-shadow: 3px 3px 12px 0px rgba(230,230,230,1);
    box-shadow: 3px 3px 12px 0px rgba(230,230,230,1);

    .header {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #FBE7E6;
    }

    .tag {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        background: #9FD367;
        background: -moz-linear-gradient(top, #9FD367 0%, #85B84A 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #9FD367), color-stop(100%, #85B84A));
        background: -webkit-linear-gradient(top, #9FD367 0%, #85B84A 100%);
        background: -o-linear-gradient(top, #9FD367 0%, #85B84A 100%);
        background: -ms-linear-gradient(top, #9FD367 0%, #85B84A 100%);
        background: linear-gradient(to bottom, #9FD367 0%, #85B84A 100%);
        color: white;
        padding: 5px 18px 5px 15px;
        position: absolute;
        left: 8px;
        top: 10px;
        font-weight: 500;
        z-index: 999;

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-right: solid 7px #85B84A;
            border-bottom: solid 10px rgba(0, 0, 0, 0);
            position: absolute;
            left: 0px;
            bottom: -10px;
            z-index: 1;
        }
    }
}
</style>