import Vue from 'vue' 
import Http from '@/api/http'

const state = {
    user: null,
    token: null
};

const getters = {
    user: state => {
        return state.user
    },
    token: state => {
        return state.token;
    },
    has_permission: state => permission => state.user.employee.permissions.includes(permission)
};

const actions = {
    logout({ commit }) {
        //Vue.prototype.router.push('/');
        commit('LOGOUT')
    },
    data({ commit }) {
        return new Promise((resolve, reject) => {
            Http.get(`auth/data`).then(response => {
                let user = response.data && response.data.user ? response.data.user : null;
                commit('SET_USER', user)
                resolve(user);
            }).catch(error => {
                reject(error)
            })
        });
    }
};

const mutations = {
    SET_USER (state, value) {
        Vue.set(state, "user", value);
    },
    UPDATE_TOKEN(state, token) {
        state.token = token
    },
    LOGOUT(state) {
        state.user = null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};